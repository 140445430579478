import {
  VStack,
  Text,
  useToast,
  Spinner,
  Button,
  HStack,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  Textarea,
  IconButton,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PatientDto } from "../../types/patient";
import { getPatientById } from "../../api/patients";
import { getClientPassConfig } from "../../api/clientpass";
import { MetricCard } from "../../components/MetricCard";
import { MetricConfWithDetail } from "../../types/clientpass";
import { Logo } from "../../Logo";
import { ShareAndroid, Copy } from "react-coolicons";
import { ROUTES } from "../../constants";

const ClientPass = () => {
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<PatientDto>();
  const [metrics, setMetrics] = useState<MetricConfWithDetail[]>();
  const toast = useToast();
  const { clientId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleShare = async () => {
    onOpen();
  };

  const handleCopy = async () => {
    const viewVitalsUrl = window.location.href.replace(ROUTES.CLIENT_PASS, ROUTES.VIEW_METRIC_DATA);
    const message =
      "I've started recording my health information on Speedback, my digital health passport. Click here to view my information: " +
      viewVitalsUrl;

    try {
      await navigator.clipboard.writeText(message);
      toast({
        title: "Message copied!",
        description: "You can now share this with your trusted caregiver",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed to copy message",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchClientById = async (id: string) => {
      try {
        setLoading(true);
        const resp = await getPatientById(id);
        if (resp.data) {
          setPatient(resp.data);
          setLoading(false);
          return;
        }
      } catch (error: any) {
        toast({
          title: "Failed to fetch patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    const fetchClientPassConfig = async (id: string) => {
      setLoading(true);
      const resp = await getClientPassConfig(id);
      if (resp.message || !resp.data) {
        toast({
          title: "Failed to fetch vitals",
          description: resp.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      setMetrics(resp.data.metrics);
      setLoading(false);
    };
    if (clientId) {
      fetchClientById(clientId);
      fetchClientPassConfig(clientId);
    }
  }, [clientId]);

  return (
    <VStack spacing={4} alignContent={"center"} margin={4} textAlign={"center"}>
      <Logo />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ClientPassBody
            patient={patient}
            metrics={metrics}
            clientId={clientId as string}
            onShare={handleShare}
          />
          <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Share with Caregivers</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Text mb={4}>
                  Invite caregivers to your health passport so they know how
                  you're doing, and can add measurements on your behalf.
                </Text>
                <Alert status="warning" mb={4}>
                  <AlertIcon />
                  Please only share this link and your PIN with trusted
                  caregivers.
                </Alert>
                <Button
                  leftIcon={<Copy />}
                  onClick={handleCopy}
                  colorScheme="blue"
                  size="lg"
                  width="full"
                >
                  Copy Link
                </Button>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </VStack>
  );
};

interface ClientPassBodyProps {
  patient?: PatientDto;
  metrics?: MetricConfWithDetail[];
  clientId: string;
  onShare: () => void;
}

const ClientPassBody = (props: ClientPassBodyProps) => {
  return (
    <>
      <VStack spacing={0} mb={4} w="full">
        <Text fontSize={"2xl"} fontWeight={"semibold"}>
          {`Hello, ${props.patient?.preferredName || props.patient?.name} 👋`}
        </Text>
        <Text>{"What are you recording today?"}</Text>
      </VStack>
      <VStack spacing={4} alignContent={"center"} textAlign={"center"} mb={6}>
        {props.metrics?.map(
          (metric) =>
            metric.enabled && (
              <MetricCard
                metricDetail={metric.detail}
                adminView={false}
                clientId={props.clientId}
              />
            )
        )}
      </VStack>
      <Box w="full" textAlign="center">
        <Button
          leftIcon={<ShareAndroid />}
          onClick={props.onShare}
          colorScheme="blue"
          variant="ghost"
          fontSize="md"
        >
          Share with caregiver
        </Button>
      </Box>
    </>
  );
};

export default ClientPass;
