import { FC, useEffect, useState } from 'react';
import { MetricDetail } from '../../types/metricDetail';
import { getMetricDetail } from '../../api/metricDetail';
import {
    useToast,
    Spinner,
    Center,
    VStack,
    Divider,
    Container,
    Box,
    Button,
    Text,
    Heading,
    Icon,
    HStack,
    FormLabel,
    FormControl,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Alert,
    AlertIcon,
    useDisclosure,
    IconButton,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Logo } from '../../Logo';
import FormField from "../../components/Form/FormField";
import { FormTypeField, ResponseType } from '../../types/form';
import { ArrowLeftMd } from 'react-coolicons';
import { Copy, ShareAndroid } from 'react-coolicons';
import { useNavigate } from 'react-router-dom';
import { createAllPatientMetrics } from '../../api/patientMetric';
import { PatientMetricDto } from '../../types/form';
import { ROUTES } from '../../constants';
import { convDateTimeToISO } from '../../utils/date';

const MetricForm: FC = () => {
    const toast = useToast()
    const [metricDetail, setMetricDetail] = useState<MetricDetail>()
    const [loading, setLoading] = useState(true)
    const [propertyValues, setPropertyValues] = useState<Record<string, string>>({})
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [recordedDate, setRecordedDate] = useState(new Date())
    const { metricId, clientId } = useParams();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleShare = () => {
        onOpen();
    };

    const onPropertyChange = (key: string, value: string) => {
        setPropertyValues({ ...propertyValues, [key]: value })
    }
    const fetchMetricDetail = async () => {
        setLoading(true)
        let resp = await getMetricDetail(metricId as string)
        if (resp.message || !resp.data) {
            toast({
                title: "Failed to fetch metric detail",
                description: resp.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setMetricDetail(resp.data)
        setLoading(false)
    }
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let patientMetrics: PatientMetricDto[] = []
        for (let propertyId in propertyValues) {
            patientMetrics.push({
                id: "", // empty id for creating new patient metric
                patientId: clientId as string,
                propertyId: propertyId,
                value: parseFloat(propertyValues[propertyId]),
                timestamp: recordedDate.getTime().toString(),
            })
        }
        try {
            await createAllPatientMetrics(patientMetrics)
            toast({
                title: "Success",
                description: "Data saved successfully!",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setIsSubmitted(true)
        } catch (error: any) {
            toast({
                title: "Failed to save data",
                description: error?.message || "An error occurred",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    useEffect(() => {
        fetchMetricDetail()
    }, [metricId])

    const handleCopy = async () => {
        const viewVitalsUrl = window.location.href.replace(ROUTES.METRIC_FORM, ROUTES.VIEW_METRIC_DATA);
        const message =
            "I've started recording my health information on Speedback, my digital health passport. Click here to view my information: " +
            viewVitalsUrl;
        try {
            await navigator.clipboard.writeText(message);
            toast({
                title: "Message copied!",
                description: "You can now share this with your trusted caregiver",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (err) {
            toast({
                title: "Failed to copy message",
                description: "Please try again",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    if (loading) {
        return (
            <Center h={"100vh"}>
                <Spinner />
            </Center>
        )
    }
    if (isSubmitted) {
        return <ThankYouSection />
    }
    return (
        <>
            <Icon
                as={ArrowLeftMd}
                color={"blue.900"}
                fontSize={"4xl"}
                onClick={() => { navigate(-1) }}
                mt={5}
                ml={5}
            />
            <VStack ml={5} mr={5}>
                <Logo />
                <Heading as="h1" size="lg">{metricDetail?.title}</Heading>
                <Text>{`${metricDetail?.unitDescription}`}</Text>
                <Box position="relative" w="full" textAlign="right">
                    <IconButton
                        aria-label="Share health data"
                        icon={<ShareAndroid />}
                        onClick={handleShare}
                        colorScheme="blue"
                        variant="ghost"
                        fontSize="24px"
                    />
                </Box>
                <Divider mb={4} />
                <Container maxW="full" textAlign={"left"}>
                    <Box as="form" onSubmit={onSubmit}>
                        {metricDetail?.properties.map((property, index) => {
                            let field: FormTypeField = {
                                key: property.propertyId,
                                question: property.question,
                                responseType: ResponseType.PROPERTY,
                                label: property.propertyId,
                                isMultiSelect: false,
                                required: property.required,
                            }
                            return (
                                <FormField
                                    key={property.propertyId}
                                    questionNum={index + 1}
                                    field={field}
                                    handleInputChange={onPropertyChange}
                                    mb={8}
                                />
                            );
                        })}
                        <FormControl isRequired>
                            <FormLabel>{"Date & Time of recording"}</FormLabel>
                            <Input
                                width={"full"}
                                type='datetime-local'
                                onChange={(e) => { setRecordedDate(new Date(e.target.value)) }}
                                value={convDateTimeToISO(recordedDate)}
                            />
                        </FormControl>
                        <Button mt="4" type="submit" width={"full"}>
                            Submit
                        </Button>
                    </Box>
                </Container>
            </VStack>

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Share with caregivers</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Text mb={4}>
                            Invite your caregivers to your health passport so they know how
                            you're doing, and can contribute readings on your behalf.
                        </Text>
                        <Alert status="warning" mb={4}>
                            <AlertIcon />
                            Please only share this link and your PIN with trusted caregivers.
                        </Alert>
                        <Button
                            leftIcon={<Copy />}
                            onClick={handleCopy}
                            colorScheme="blue"
                            size="lg"
                            width="full"
                        >
                            Copy Link
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

const ThankYouSection: FC = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    return (
        <Center mt={20} w={"full"}>
            <VStack>
                <Logo />
                <Heading as="h1" size="lg">Thank you!</Heading>
                <Text>One step goes a long way</Text>
                <Divider m={2} color={"blue.900"} />
                <HStack>
                    <Button
                        onClick={() => { navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.VIEW_METRIC_DATA}`) }}
                    >
                        View Trends
                    </Button>
                    <Button
                        onClick={() => { navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.CLIENT_PASS}`) }}
                    >
                        Record More
                    </Button>
                </HStack>
            </VStack>
        </Center>
    )
}

export default MetricForm;
